import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithRef, forwardRef } from 'react'

import { DatoStructuredText } from '@/features/common/'
import { GatsbyImageFocused } from '@/features/common/'
import { ScrollToggle } from '@/features/common/'
import { DatoLink } from '@/features/dato-link'
import { useMainNavContext } from '@/features/layout'
import { useEscKeyFunction } from '@/hooks/useEscKeyFunction'
import { absoluteFill, bezier, mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { NavLinkGroup } from './NavLinkGroup'

interface Props extends ComponentPropsWithRef<'section'> {
  data?: Queries.MainNavItemFragment | null
  breakpoint?: NonNullable<
    Queries.MainNavQuery['datoCmsMainNav']
  >['breakpoint']
}

export const MainNavItem = forwardRef<HTMLElement, Props>(
  ({ data, breakpoint, ...props }, ref): JSX.Element => {
    const {
      activeNavItemId,
      prevActiveNavItemId,
      setActiveNavItemId,
      setMobileNavIsOpen,
    } = useMainNavContext()
    const isOpen = activeNavItemId === data?.id

    const handleBack = () => {
      setActiveNavItemId(null)
    }

    const handleClose = () => {
      setActiveNavItemId(null)
      setMobileNavIsOpen(false)
    }

    useEscKeyFunction(handleClose)

    const styles = {
      section: css`
        position: absolute;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        width: 100%;
        height: calc(100% - var(--nav-height-flex));
        margin-top: var(--nav-height-flex);
        background: #fff;
        transform: translate3d(0, max(-18rem, -100%), 0);
        opacity: 0;
        transition:
          opacity 150ms ${bezier.easeOut} 50ms,
          transform 200ms ${bezier.easeOut};
        ${isOpen &&
        css`
          pointer-events: all;
          z-index: 2;
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition-delay: 0ms;
          ${prevActiveNavItemId !== null &&
          css`
            transition-delay: 250ms;
          `}
        `}
        @media (max-width: ${breakpoint}px) {
          opacity: 1;
          transform: translate3d(100%, 0, 0);
          ${isOpen &&
          css`
            transform: translate3d(0, 0, 0);
          `};
        }
      `,
      contentWrapper: css`
        position: relative;
        display: grid;
        height: 100%;
        ${data?.layout === 'DEFAULT' &&
        css`
          grid-template-columns: 2fr 1fr;
        `}
        ${data?.layout === 'MULTI_GROUP' &&
        css`
          grid-template-columns: 5fr 4fr;
        `}
        ${mq().m} {
          grid-template-columns: 1fr;
          justify-items: flex-start;
          align-content: flex-start;
          padding: var(--row-72) var(--margin);
          box-sizing: border-box;
          overflow: auto;
        }
      `,
      overview: css`
        position: relative;
        padding: var(--row-72) var(--margin);
        box-sizing: border-box;
        height: 100%;
        overflow: auto;
        ${mq().m} {
          display: contents;
        }
      `,
      heading: css`
        position: relative;
        font-size: var(--fs-84);
        text-transform: uppercase;
        color: ${colors.gray10};
        border-bottom: 2px solid ${colors.red};
        margin: 0 0 0.5em;
        padding-bottom: 0.333em;
        max-width: 18ch;
        ${mq().m} {
          max-width: calc(100% - 4rem);
          font-size: var(--fs-60);
        }
      `,
      description: css`
        position: relative;
        max-width: 66ch;
        line-height: 1.75;
        color: ${colors.gray10};
        font-weight: 450;
        ${mq().m} {
          order: 3;
          padding-top: 1em;
          margin-top: 2em;
          border-top: 2px solid rgba(0, 0, 0, 0.1);
          color: ${colors.gray20};
          font-style: italic;
          p {
            margin: 0.25em 0 0.5em;
          }
        }
      `,
      backgroundImage: css`
        ${absoluteFill}
        grid-column: 1 / 2;
        ${mq().m} {
          display: none;
        }
      `,
      linkArea: css`
        padding: var(--row-72) var(--margin);
        box-sizing: border-box;
        height: 100%;
        overflow: auto;
        ${mq().m} {
          display: contents;
        }
      `,
      linkList: css`
        margin: 0;
        padding: 0;
        list-style: none;
        box-sizing: border-box;
        opacity: 0;
        transition: opacity 600ms ease;
        ${isOpen &&
        css`
          transition-delay: 100ms;
          opacity: 1;
          ${prevActiveNavItemId !== null &&
          css`
            transition-delay: 300ms;
          `}
        `}
        ${data?.layout === 'MULTI_GROUP' &&
        css`
          columns: 2;
          gap: 3em;
          ${mq().m} {
            margin-top: 1em;
          }
          ${mq().s} {
            columns: 1;
          }
        `}
        @media (max-width: ${breakpoint}px) {
          opacity: 1;
        }
      `,
      link: css`
        margin: 0.25em 0;
        font-size: var(--fs-21);
        font-weight: 350;
        line-height: 1.25;
        @media (hover: hover) {
          &:hover {
            color: ${colors.red};
          }
        }
      `,
      closeButton: css`
        position: fixed;
        top: 0;
        right: 0;
        width: 1.5em;
        height: 1.5em;
        padding: 1rem;
        margin: 2rem calc(var(--margin) - 1rem);
        display: flex;
        box-sizing: content-box;
        > svg {
          width: 100%;
          height: 100%;
          flex: 1;
          color: ${colors.gray45};
          transform: scale3d(1, 1, 1);
          transition:
            transform 500ms ${bezier.bounce},
            color 300ms ease;
          path {
            stroke-width: 2px;
            stroke: currentColor;
            fill: none;
          }
        }
        @media (hover: hover) {
          &:hover {
            > svg {
              transform: scale3d(1.1, 1.1, 1);
              color: ${colors.red};
            }
          }
        }
      `,
      closeX: css`
        display: block;
        @media (max-width: ${breakpoint}px) {
          display: none;
        }
      `,
      closeArrow: css`
        display: none;
        @media (max-width: ${breakpoint}px) {
          display: block;
        }
      `,
    }
    return (
      <section
        css={styles.section}
        aria-hidden={!isOpen}
        ref={ref}
        {...props}
      >
        {isOpen && <ScrollToggle />}
        <div css={styles.contentWrapper}>
          <GatsbyImageFocused
            css={styles.backgroundImage}
            image={data?.backgroundImage?.gatsbyImageData}
            aspectRatio={1}
            data={data?.backgroundImage}
          />
          <div css={styles.overview}>
            <h2 css={styles.heading}>{data?.heading}</h2>
            <div css={styles.description}>
              <DatoStructuredText data={data?.description} />
            </div>
          </div>
          <div css={styles.linkArea}>
            <ul css={styles.linkList}>
              {data?.layout === 'DEFAULT' &&
                data?.links?.map((link, i) => (
                  <li key={i}>
                    <DatoLink
                      data={link}
                      css={styles.link}
                      styleVariant="LOWERCASE"
                      disableLightboxLinks
                      onClick={handleClose}
                      tabIndex={isOpen ? 0 : -1}
                    />
                  </li>
                ))}
              {data?.layout === 'MULTI_GROUP' &&
                data?.linkGroups?.map((linkGroup, i) => (
                  <li key={i}>
                    <NavLinkGroup
                      data={linkGroup}
                      isOpen={isOpen}
                      onClickLink={handleClose}
                    />
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <button
          aria-label="Close Nav"
          onClick={handleBack}
          css={styles.closeButton}
          tabIndex={isOpen ? 0 : -1}
        >
          <svg viewBox="0 0 24 24">
            <g css={styles.closeX}>
              <path
                d="M1 23L12 12L1 1"
                vectorEffect="non-scaling-stroke"
              />
              <path
                d="M23 1L12 12L23 23"
                vectorEffect="non-scaling-stroke"
              />
            </g>
            <g css={styles.closeArrow}>
              {/* <path
                d="M17 1L6 12L17 23"
                vectorEffect="non-scaling-stroke"
              /> */}
              <path
                d="M13 1L2 12L13 23"
                vectorEffect="non-scaling-stroke"
              />
              <path
                d="M2 12H24"
                vectorEffect="non-scaling-stroke"
              />
            </g>
          </svg>
        </button>
      </section>
    )
  }
)

MainNavItem.displayName = 'MainNavItem'

export const MainNavItemFragment = graphql`
  fragment MainNavItem on DatoCmsMainNavItem {
    __typename
    id: originalId
    label
    layout
    links {
      ... on DatoCmsPageLink {
        ...PageLink
      }
      ... on DatoCmsExternalLink {
        ...ExternalLink
      }
      ... on DatoCmsTertiaryArticleLink {
        ...TertiaryArticleLink
      }
      ... on DatoCmsFormLink {
        ...FormLink
      }
    }
    linkGroups {
      ...NavLinkGroup
    }
    heading
    description {
      value
    }
    backgroundImage {
      gatsbyImageData(
        width: 1280
        sizes: "(max-width: 540px) 100vw, 60vw"
        imgixParams: {
          q: 60
          ar: "1:1"
          fit: "crop"
          crop: "focalpoint"
          sat: -100
          con: -50
          bri: 60
          exp: -30
        }
      )
      ...ImageFocalData
    }
  }
`
